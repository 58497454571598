<template>
	<div class="ui grid">

		<!--header-->
		<div class="ui row">
			<div class="sixteen wide column">
				<div class="suivi-card-title">{{current_step.label}}</div>
			</div>
		</div>

		<!--content-->
		<div class="ui row">
			<div class="sixteen wide center aligned column">

				<div class="ui placeholder basic segment">
					<div class="ui header">
						<p class="suivi-card-message">
							Veuillez téléverser les scans des documents nécessaires pour votre inscription.
						</p>
					</div>
					<div class="ui primary button" @click="$router.push({name: 'dossier-upload'})">Scans des documents</div>
				</div>

			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: "suiviDemandes.actions.upload_docs",
		computed: {
			current_step() {
				return this.$store.getters.current_step
			}
		}
	}
</script>
