<template>
    <div class="ui grid">

        <!--header-->
        <div class="ui row">
            <div class="sixteen wide column">
                <div class="suivi-card-title">inscription administrative</div>
            </div>
        </div>

        <!--content-->
        <div class="ui row">
            <div class="sixteen wide center aligned column">
                <p :class="current_step.status==='rejected'? 'rejected_message' : 'suivi-card-message'">
                    {{message}}
                </p>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "suiviDemandes.actions.inscription_administrative",
        computed: {
            current_step() {
                return this.$store.getters.current_step
            },
            message() {
                let msg = "En cours de validation par l'administration . . ."
                if (this.current_step.status === "rejected") {
                    msg = "Votre dossier est rejeté";
                }
                return msg;
            }
        }
    }
</script>
