<template>
	<div class="ui grid">

		<!--header-->
		<div class="ui row">
			<div class="sixteen wide column">
				<div class="suivi-card-title">notification</div>
			</div>
		</div>

		<!--content-->
		<div class="ui row">
			<div class="sixteen wide center aligned column">
				<p class="rejected_message" v-if="current_step.status === 'rejected'">
					Votre dossier est rejeté
				</p>
				<p class="suivi-card-message" v-else>
					{{message}}
				</p>
				<uploadItem v-for="(file, index) in rejectedAttachements" :key="file._id" :fileName="fileName_upload(file.type)" :labo="current_user_labo" :message="message_upload(file.type)"
							:fileType="file.type" @uploadCompleted="(data) => onUploadCompleted(data, file, index)"/>
			</div>
		</div>

	</div>
</template>

<script>
	const uploadItem = () => import('@/components/uploadItem')
	export default {
		name: "suiviDemandes.actions.verify_docs",
		components: {uploadItem},
		props : {
			refresh :{
				type: Boolean,
				default: false
			}


		},
		data() {
			return {
				rejectedAttachements: [],
				staticFiles: this.$SHARED.consts.staticFiles,
			}
		},
		computed: {
			dossier() {
				return this.$store.state.dossier
			},
			current_step() {
				return this.$store.getters.current_step
			},
			message() {
				return this.rejectedAttachements.length
					? 'Document(s) invalide(s), veuillez les téléversés de nouveau'
					: 'En cours de validation des documents . . .'
			},
			current_user_labo(){
				return this.$store.state.current_user.labo
			}
		},
		watch:{
			refresh(){
				console.log("refresh ...")
				this.getRejectedAttachements();
			}
		},
		methods: {
			getRejectedAttachements() {
				console.log('getRejectedAttachements')
				const query = {query: {id_dossier: this.dossier._id, status: 'rejected'}, options: {}}
				this.$store.dispatchAsync(this.$SHARED.services.attachement.list, query).then(data => {
					this.rejectedAttachements = data
				})
				this.refresh = false;
			},
			onUploadCompleted(newFile, oldFile, index) {
				console.log('onUploadCompleted');
				const query = {...newFile, _id: oldFile._id, status: 'waiting'}
				const data ={
					update: query,
					removeOldFile : true,
					filePath : oldFile.path
				}
				this.$store.dispatchAsync(this.$SHARED.services.attachement.update, data).then(() => {
					this.$notify({
						group: 'user-message',
						type: 'success', // warn , error, success, info
						text: this.$SHARED.messages.attachment.upload_succeeded
					})
					console.log("@@@@@@@ index", index)
					this.rejectedAttachements.splice(index, 1);
				})
			},
			message_upload(type){
				return this.staticFiles.filter(e=> e.fileType === type)[0].message
			},
			fileName_upload(type){
				console.log("@@@@@@@ totoototototototto ", this.staticFiles.filter(e=> e.fileType === type)[0])
				return this.staticFiles.filter(e=> e.fileType === type)[0].fileName
			}
		},
		mounted() {
			this.getRejectedAttachements();
		}
	}
</script>
