<template>
	<div class="ui grid">

		<!--header-->
		<div class="ui row">
			<div class="sixteen wide column">
				<div class="suivi-card-title">validation de l'administration</div>
			</div>
		</div>
		<p class="text-orange text-bold">
			<small style="font-size: 14px">
				* Veuillez télécharger les modèles de documents ci-dessous en
				<a href="http://fsjes-agdal.um5.ac.ma/fr/documents-administratifs-a-telecharger-doctorants" target="_blank"> <u>  cliquant ici</u></a>
			</small>
		</p>
		<!--content-->
		<template v-if="!isVerificationDocs">
			<div class="ui row">
				<div class="sixteen wide center aligned column">
					<p class="suivi-card-message">
						Veuillez téléverser les scans des documents suivants
					</p>

					<upload-item v-for="(file, key) in staticFiles" :file-name="file.fileName" :labo="current_user_labo"
								 :message="file.message"
								 :file-type="file.sous_type" :key="key"
								 :removableFile="true"
								 @uploadCompleted="onUploadCompleted"
								 @fileRemoved="onFileRemoved"
					/>
				</div>
				<div class="sixteen wide right aligned column">
					<button class="upload-submit" @click="submitAttachments">
						Soumettre
					</button>
				</div>
			</div>
		</template>

		<template v-else>
			<div class="ui row">
				<div class="sixteen wide center aligned column">
					<p class="rejected_message" v-if="current_step.status === 'rejected'">
						Votre dossier est rejeté
					</p>
					<p class="suivi-card-message" v-else>
						{{message}}
					</p>
					<uploadItem v-for="(file, index) in rejectedAttachements" :key="file._id" :fileName="fileName_upload(file.sous_type)" :labo="current_user_labo" :message="message_upload(file.sous_type)"
								:fileType="file.sous_type" @uploadCompleted="(data) => onUploadCompleted_verify(data, file, index)"/>
				</div>
			</div>

		</template>
	</div>
</template>

<script>
	const uploadItem = () => import('@/components/uploadItem')
	export default {
		name: "suiviDemandes.actions.validation_administration",
		components: {uploadItem},
		props : {
			refresh :{
				type: Boolean,
				default: false
			}


		},
		data() {
			return {
				isBusy: false,
				uploadedFiles: [],
				//  staticFiles: this.$SHARED.consts.staticFiles,
				staticFiles: [
					/*{
						fileName: " Charte des thèses / ميثاق الأطروحات",
						sous_type: "charte_theses",
						message: ""
					},*/
					{
						fileName: "Déclaration sur l'honneur / التصريح بالشرف",
						sous_type: "declaration_honneur",
						message: ""
					},
					{
						fileName: "Demande de dérogation Master spécialisé",
						sous_type: "demande_derogation",
						message: ""
					},
					{
						fileName: " Engagement d'assiduité / التزام بالمواظبة",
						sous_type: "engagement_assiduite",
						message: ""
					}
				],
				isVerificationDocs : false,
				rejectedAttachements: []
			}
		},
		computed: {
			dossier() {
				return this.$store.state.dossier
			},
			current_user_labo() {
				return this.$store.state.current_user.labo
			},
			current_step() {
				return this.$store.getters.current_step
			},
			message() {
				return this.rejectedAttachements.length
						? 'Document(s) invalide(s), veuillez les téléverser de nouveau'
						: "En cours de validation par l'administration . . ."
			}
		},

		watch:{
			refresh(){
				console.log("refresh ...")
				this.getRejectedAttachements();
				this.getInscriptionAdministrativeAtt();
			}
		},

		methods: {
			submitAttachments() {
				console.log("`submitAttachments`")
				if (this.isBusy) return

				if (this.uploadedFiles.length !== this.staticFiles.length) {
					this.$toast.error("Veuillez télécharger tous les documents")
					return;
				}

				this.isBusy = true

				const data = {
					process: this.$SHARED.process.inscription,
					attachements: this.uploadedFiles,
					labo: this.current_user_labo,
					id_dossier: this.dossier._id
				}

				this.$store.dispatchAsync(this.$SHARED.services.attachement.create, data).then(ar => {
					this.$toast.success(this.$SHARED.messages.attachment.upload_succeeded)
					this.uploadedFiles = []
					//  this.$store.commit("SET_DOSSIER", ar.dossier)
					//  this.$store.state.current_user.labo = this.selectedLabo
					this.refresh = true
				}, code => {
					this.$toast.error(this.$SHARED.messages[code])

				}).finally(() => {
					this.isBusy = false
				})

			},
			onUploadCompleted(data) {
				console.log("`onUploadCompleted`")
				this.uploadedFiles.push({...data, sous_type: data.type, type: 'validation_administration'})
			},
			onFileRemoved(filePath){
				_.remove(this.uploadedFiles, item => item.path === filePath )
			},
			getInscriptionAdministrativeAtt() {
				console.log('getInscriptionAdministrativeAtt ... ')
				const query = {query: {id_dossier: this.dossier._id, type: 'validation_administration', sous_type :{"$in":["declaration_honneur","engagement_assiduite","demande_derogation"]}}, options: {}}
				this.$store.dispatchAsync(this.$SHARED.services.attachement.list, query).then(data => {

					data.length ? this.isVerificationDocs = true : this.isVerificationDocs = false
				})
				this.refresh = false;
			},





			getRejectedAttachements() {
				console.log('getRejectedAttachements ...')

				const query = {query: {id_dossier: this.dossier._id, type: 'validation_administration',sous_type :{"$in":["declaration_honneur","engagement_assiduite","demande_derogation"]}, status: 'rejected'}, options: {}}
				this.$store.dispatchAsync(this.$SHARED.services.attachement.list, query).then(data => {
					this.rejectedAttachements = data
				})
				this.refresh = false;
			},
			onUploadCompleted_verify(newFile, oldFile, index) {
				console.log('onUploadCompleted');

				const query = {...newFile, _id: oldFile._id, type: 'validation_administration', status: 'waiting'}
				const data ={
					update: query,
					removeOldFile : true,
					filePath : oldFile.path
				}

				this.$store.dispatchAsync(this.$SHARED.services.attachement.update, data).then(() => {
					this.$toast.success(this.$SHARED.messages.attachment.upload_succeeded)
					console.log("@@@@@@@ index", index)
					this.rejectedAttachements.splice(index, 1);
				})
			},
			message_upload(type){
				return this.staticFiles.filter(e=> e.sous_type === type)[0].message
			},
			fileName_upload(type){
				console.log("@@@@@@@ totoototototototto ", this.staticFiles.filter(e=> e.sous_type === type)[0])
				return this.staticFiles.filter(e=> e.sous_type === type)[0].fileName
			}
		},
		mounted() {
			this.getInscriptionAdministrativeAtt()
			this.getRejectedAttachements()
		}
	}
</script>
<style>
	.upload-submit {
		border: none;
		border-radius: 5px;
		color: #fff;
		text-transform: uppercase;
		font-weight: 500;
		background-color: var(--main-color-green);
		padding: 0.75em 1em;
		cursor: pointer;
	}
</style>

