<template>
	<div class="ui grid">

		<!--header-->
		<div class="ui row">
			<div class="sixteen wide column">
				<div class="suivi-card-title">validation du jury</div>
			</div>
		</div>

		<!--content-->
		<div class="ui row">
			<div class="sixteen wide center aligned column">
				<p id="suivi-card-message" :class="current_step.status==='rejected'? 'rejected_message' : 'suivi-card-message'">
					{{message}}
				</p>
			</div>
		</div>

	</div>


</template>

<script>
	export default {
		name: "suiviDemandes.actions.decision_docs",

		computed: {
			current_step() {
				return this.$store.getters.current_step
			},
			message() {
				let msg="Votre dossier est en cours de traitement par le jury . . ."
				if (this.current_step.status === "rejected"){
					msg = "Votre dossier est rejeté"
				}
				return msg;
			}
		}
	}
</script>
