<template>
	<div>
		<div class="suivi-card-title">{{current_action.name}}</div>
		<div>
			<p class="suivi-card-message ml-1">
				Veuillez déposer votre dossier physique <br/>
				a l’administration dans un delais de 15 jours.
			</p>
		</div>
		<div>
			<div class="success-suivi">
				<svg viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M67.027 9.433a2.6 2.6 0 0 0-3.677-.004L32.152 40.544 20.896 28.319a2.601 2.601 0 0 0-3.826 3.523l13.09 14.215c.479.52 1.15.823 1.857.838h.055c.688 0 1.35-.272 1.836-.758l33.114-33.026a2.6 2.6 0 0 0 .005-3.678Z"
					      fill="#09A06A"/>
					<path d="M66.4 31.9a2.6 2.6 0 0 0-2.601 2.6c0 16.156-13.143 29.299-29.299 29.299-16.155 0-29.299-13.143-29.299-29.299 0-16.155 13.144-29.299 29.299-29.299A2.6 2.6 0 1 0 34.5 0C15.477 0 0 15.476 0 34.5 0 53.523 15.476 69 34.5 69 53.523 69 69 53.523 69 34.5a2.6 2.6 0 0 0-2.6-2.6Z"
					      fill="#09A06A"/>
				</svg>
				<h3>félicitations vous êtes accepté</h3>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "suiviDemandes.situation",
		computed: {
			current_action() {
				return this.$store.getters.current_action
			}
		}
	}
</script>

